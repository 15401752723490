<template>
 <div class="auth flex flex-col md:flex-row items-center">
    <div class="flex-1 flex justify-end hidden md:block">
      <img alt="Música Protegida" src="@/assets/logo.svg" class="ml-4 logo" style="max-width: 420px">
    </div>
    
    <div class="right_part">
      <h1 class="color_white mb-4" style="font-size: 40px;">Verificação de E-mail</h1>
      <div class="box edit_box w-full" style="max-height: 170px;">
        <div class="text-center flex flex-col align-center" v-if="status">
            <p :class="status" class="mb-4 font-bold">{{ message }}</p>
            <router-link to="/login" class="btn btn-grad primary">Log in</router-link>
            </div>
      </div>
    </div>
</div>
</template>

<script>
import openAPI from '@/api'

export default {
    name : 'VerifyEmail',
    data () {
        return {
          status: false,
          message: ''
        }
    },
    mounted() {
        let token = this.$route.params.token;
        this.validate(token);
    },
    methods: {
        async validate(token) {
            this.loading = true
            let data = await openAPI.get('user/verify-email/' + token)
            if(data.errors) {
              this.status = 'text-red'
              this.message = 'The validation token is invalid.'
            }else {
              this.status = 'text-green'
              this.message = 'Your e-mail was successfully validated. You can now log in.'
            }
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
